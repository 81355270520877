import React,{useState,useEffect,useRef} from 'react';
import {connect} from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import {API_URL} from '../../../config.json';
import InvoiceBody from './debitor_receipt_body'
import {currentDateTime,convertNumberToWords, dateTimeFormat,dateFormat} from '../../../lib/functions'
import PrintIcon from '@material-ui/icons/Print';
import moment from 'moment';
import ReactToPrint from "react-to-print";
import Font, {Text} from 'react-font'

import '../../commons/voucher.css'
import commaNumber from 'comma-number';
let format = commaNumber.bindWith(',', '.')

class PrintAbleSection extends React.Component {
      
  constructor(props) {
    super(props); 


  }

  state = {
    receipt:[],
    institution:null,
    accDue:0,
  }

  componentDidMount() {
    this.getPurchaseOrder()
    this.setState({institution:this.props.institution});
    
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.rcv_id != this.props.rcv_id) {
        this.getPurchaseOrder()
    }

  }
   
   getPurchaseOrder = async()=>{
      let accId = null
      await axios.post(`${API_URL}/api/get-debitor-rcv-with-details`,{rcv_id:this.props.rcv_id,from:'voucher'},{headers:{'auth-token':this.props.authInfo.token}}).then(res=>{
       if(res.data.length == 0) return false

        // Detail Data to Product Cart - Start
    
        accId = res.data[0].acc_id

        this.setState({receipt:res.data[0]});
        
      });

      await axios.post(`${API_URL}/api/get-sundry-debitor-balance`,{customerId:accId},{headers:{'auth-token':this.props.authInfo.token}}).then( (res)=>{
        if(res.data.accounts.length ==0){
          this.setState({accDue:0})
          return false
        } 
        this.setState({accDue:res.data.accounts[0].balance})
       })

      
  }



  render(){
    let {receipt,institution,accDue}  = this.state;
    return(
      <div style={{padding:'20px',paddingBottom:'0px'}} >
        {
          receipt.details !=undefined?(
              <>
                <Grid container>
                            <Grid item xs={12} sm={12}>
                                 <h3 className={"invoice-title"}>Debtor/ Customer Receipt</h3>
                            </Grid>
                      </Grid>

                      <Grid container style={{marginBottom: '12px',fontSize:'14px',color:"#222"}}>
                           <Grid item xs={6} sm={6}> 
                                 <strong>Debtor/ Customer Code : </strong> <span>{ receipt.acc_code }</span><br/>
                                 <strong> Name : </strong> <span>{ receipt.acc_name  }</span><br/>
                                 <strong>Institution  : </strong> <span>{receipt.institution_name  }</span><br/>
                                 <strong> Address : </strong> <span>{receipt.address }</span><br/>
                                 <strong> Contact No : </strong> <span>{ receipt.contact_no  }</span><br/>
                            </Grid> 
                            <Grid item xs={6} sm={6} style={{textAlign:'right'}}>
                                <strong>Receipt No : </strong> <span>{ receipt.rcv_code }</span><br/>  
                                 <strong>Receipt Date  : </strong> <span>{ moment(receipt.creation_date).format(dateFormat)  }</span><br/>
                                 <strong>Receipt By  : </strong> <span>{ receipt.user_full_name }</span><br/>

                            </Grid>
                      </Grid>
                     
                      <InvoiceBody purchaseOrderData={receipt} institution={institution} authInfo={this.props.authInfo}/>

                      <Grid container>
                            <Grid xs={4} sm={4} style={{marginTop: '2px'}}>
                            <table style={{width:'100%',paddingRight: '20px'}}>
                                    
                                        <tbody>
                                       
                                        <tr>
                                          <td style={{color: '#222',fontWeight:'bold'}}>Previous  Due : </td>
                                          <td style={{textAlign:"right",color: '#222',fontWeight:'bold'}}> {format(Math.abs(parseFloat((accDue+receipt.rcv_total) + receipt.discount_total).toFixed(2)))+'.00'} </td>
                                        </tr>

                                        <tr>
                                          <td style={{color: '#222',fontWeight:'bold'}}>Discount   : </td>
                                          <td style={{textAlign:"right",color: '#222',fontWeight:'bold'}}> {format(parseFloat(receipt.discount_total).toFixed(2))} </td>
                                        </tr>

                                        <tr>
                                          <td style={{color: '#222',fontWeight:'bold'}}>Received   : </td>
                                          <td style={{textAlign:"right",color: '#222',fontWeight:'bold'}}> {format(parseFloat(receipt.rcv_total).toFixed(2))} </td>
                                        </tr>

                                        <tr >
                                          <td style={{background: '#ccc',height: '0px'}}></td>
                                          <td style={{background: '#ccc',height: '0px'}}></td>

                                        </tr>

                                        <tr>
                                          <td style={{color: '#222',fontWeight:'bold'}}>Current  Due : </td>
                                          <td style={{textAlign:"right",color: '#222',fontWeight:'bold'}}> {format(parseFloat(accDue).toFixed(2))} </td>
                                        </tr>
                                        </tbody>
                                       

                                  </table>
                            </Grid>

                            <Grid  xs={2} sm={2}>

                            </Grid>
                            <Grid xs={6} sm={6} style={{marginTop: '2px'}}>
                                      <table style={{width:'100%'}}>

                                    
                                      <tbody>
                                      
                                        <tr>
                                          <td style={{color: '#222',fontWeight:'bold'}}>Received Total : </td>
                                          <td style={{textAlign:"right",color: '#222',fontWeight:'bold'}}> {format(parseFloat(receipt.rcv_total).toFixed(2))} </td>
                                        </tr>
                                       
                                        </tbody>
                                      
                                  </table>
                            </Grid>

                            <Grid xs={12} sm={12}>
                                  <p style={{color: '#222'}}>In Word of Grand Total : {convertNumberToWords(receipt.rcv_total)} </p>
                                  <p style={{color: '#222'}}>Narration : <p style={{whiteSpace: 'pre-line',margin: '0',marginLeft: '10px',padding: '0'}}>{receipt.narration}</p> </p>
                            </Grid>

                      </Grid>
              </>

          ):''
        }
         
      </div>
    )
  }
}


class ComponentToPrint extends React.Component {

   
  constructor(props) {
    super(props);  
     }
  state = {
    authInfo:this.props.authInfo,
    rcv_id:this.props.rcv_id,
    institution:this.props.institution,
  }


  render() {
      let institution =   this.props.institution;
    let a4css = `
    .a4 {
      font-size: 16px;
      
  }
  .a4 body, table{
      font-size: 14px;
  }
    `

    let hafa4css = `
    .hafa4 { 
      width:500px !important;
  }
  .hafa4 body, table{
      font-size: 14px;
  }
    `

    let poscss = `
    .pos {
      width: 302px !important;
    }
    .pos body{
      font-size: 14px;
    }
  .pos body, table{
      font-size: 14px;
  }
    `


   
    return (
      <div className='print-source' >
           {/* Print  DOCUMENT */}

                 {/* {   A4 Print */
                    institution != null &&  institution.pro_print_type == 'a4'?(
                      <html lang="en">
                    <head>
                       <meta charset="UTF-8" />
                        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                        <meta http-equiv="X-UA-Compatible" content="ie=edge" />
                        <title>PURCHASE  VOUCHER</title>
                        <style >
                           {a4css}
                        </style>
                    </head>
                    <tbody className="a4" style={{padding:'5px'}}>



                     

                     <table style={{width:'100%'}}>

                       <thead>
                          <tr>
                            <td><div style={{height:'10px'}}></div></td>
                            </tr>
                       </thead>

                       <tbody>
                         {/* <tr>
                           <td>
                            
                        <div className="invoice-head" style={{width:'100%'}}>
                            <div className="invoice-logo" style={{width:'20%',float:'left',marginLeft:'0%',marginTop:'20px',overflow:'hidden'}}>
                                  <image alt="logo" src={`${API_URL}/${institution.pro_logo}`} style={{width:'100px',height:'100px'}} />
                            </div>
                            <div style={{width:'5%'}}></div>
                            <div className="invoice-desc" style={{width:'75%',float:'left',marginLeft:'5px'}}>
                                  <h2 style={{textAlign:'center'}}>{institution.pro_name}</h2>
                                  <Font family='Roboto'  sans-serif>
                                  <p style={{textAlign:'center',color:'#222',fontSize:'16px',margin:'0px',padding:'0px'}}>{institution.pro_desc}</p>

                                  </Font>
                            </div>
                        </div>
                           </td>
                           </tr> */}
                         <tr>
                           <td>
                           <PrintAbleSection   authInfo={this.state.authInfo} rcv_id={this.props.rcv_id} institution={institution} />

                           </td>
                           
                         </tr>


                         <tr>
                           <td>
                           <div className="invoice-footer-bottom"  >
                      <div className="invoice-footer" style={{marginTop:'60px'}}>
                                <p style={{float:'left',marginLeft:'20px',borderTop:'1px solid #222',width:'150px',textAlign:'center'}}>Received By</p>
                           
                                 <p style={{float:'right',marginRight:'25px',borderTop:'1px solid #222',width:'150px',textAlign:'center'}}>Authorized By</p>
                      </div>


                      <div className="invoice-footer" >
                       
                            <p style={{float:'left',fontSize:'12px',marginLeft:'20px',color:'#222',fontWeight:'bold'}}>
                                Print Date:  {moment().format(dateFormat)} 
                           </p>
                           <p style={{float:'right',marginRight:'12px',marginRight:'25px',fontSize:'10px',color:'#222',fontWeight:'bold',padding:'0px'}}>
                                {/* Developed By : Soft Task - Contact No = 01749-508007 */}
                           </p>
                      </div>
                      
                      </div>
                           </td>
                         </tr>
                       </tbody>

                       <tfoot >
                         <tr>
                         <td>
                           <div style={{height:'100px'}}></div>
                           </td>
                         </tr>
                       </tfoot>
                     </table>

                      
                    </tbody>
                    </html>
                     ):''
                 }
                    


                  {/* {  1/2 - A4   Print */
                    institution != null &&  institution.pro_print_type == '1/2a4'?(
                      <html lang="en">
                      <head>
                         <meta charset="UTF-8" />
                          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                          <meta http-equiv="X-UA-Compatible" content="ie=edge" />
                          <title>PURCHASE  VOUCHER</title>
                          <style >
                             {hafa4css}
                          </style>
                      </head>
                      <tbody  className="hafa4" style={{padding:'5px'}}>

                       <table style={{width:'100%'}}>
  
                         <thead>
                            <tr>
                              <td><div style={{height:'10px'}}></div></td>
                              </tr>
                         </thead>
  
                         <tbody>
                           <tr>
                             <td>
                              
                          <div className="invoice-head" style={{width:'100%'}}>
                              <div className="invoice-logo" style={{width:'20%',float:'left',marginLeft:'0%',marginTop:'20px',overflow:'hidden'}}>
                                    <image alt="logo" src={`${API_URL}/${institution.pro_logo}`} style={{width:'100px',height:'100px'}} />
                              </div>
                              <div style={{width:'5%'}}></div>
                              <div className="invoice-desc" style={{width:'75%',float:'left',marginLeft:'5px'}}>
                                    <h2 style={{textAlign:'center'}}>{institution.pro_name}</h2>
                                    <Font family='Roboto'  sans-serif>
                                    <p style={{textAlign:'center',color:'#222',fontSize:'15px',margin:'0px',padding:'0px'}}>{institution.pro_desc}</p>
  
                                    </Font>
                              </div>
                          </div>
                             </td>
                             </tr>
                           <tr>
                             <td>
                             <PrintAbleSection   authInfo={this.state.authInfo} rcv_id={this.props.rcv_id} institution={institution} />
  
                             </td>
                             
                           </tr>
  
  
                           <tr>
                             <td>
                             <div className="invoice-footer-bottom "  >
                        <div className="invoice-footer" style={{marginTop:'60px'}}>
                                  <p style={{float:'left',marginLeft:'20px',borderTop:'1px solid #222',width:'150px',textAlign:'center'}}>Received By</p>
                             
                                   <p style={{float:'right',marginRight:'25px',borderTop:'1px solid #222',width:'150px',textAlign:'center'}}>Authorized By</p>
                        </div>
  
  
                        <div className="invoice-footer " >
                         
                              <p style={{float:'left',fontSize:'12px',marginLeft:'20px',color:'#222',fontWeight:'bold'}}>
                                  Print Date  :  {moment().format(dateFormat)} 
                             </p>
                             <p style={{float:'right',marginRight:'12px',marginRight:'25px',fontSize:'10px',color:'#222',fontWeight:'bold',padding:'0px'}}>
                                  {/* Developed By : Soft Task - Contact No = 01749-508007 */}
                             </p>
                        </div>
                        
                        </div>
                             </td>
                           </tr>
                         </tbody>
  
                         <tfoot >
                           <tr>
                           <td>
                             <div style={{height:'100px'}}></div>
                             </td>
                           </tr>
                         </tfoot>
                       </table>
  
                        
                      </tbody>
                      </html>
                     ):''
                 }


                  {/* {   A4 Print */
                    institution != null &&  institution.pro_print_type == 'pos'?(
                      <html lang="en">
                      <head>
                         <meta charset="UTF-8" />
                          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                          <meta http-equiv="X-UA-Compatible" content="ie=edge" />
                          <title>PURCHASE  VOUCHER</title>
                          <style >
                             {poscss}
                          </style>
                      </head>
                      <tbody  className="pos" style={{padding:'5px'}}>

                       <table  className="pos">
  
                         <thead>
                            <tr>
                              <td><div style={{height:'10px'}}></div></td>
                              </tr>
                         </thead>
  
                         <tbody>
                           <tr>
                             <td>
                              
                          <div className="invoice-head pos">
                              <div className="invoice-logo" style={{width:'35%',float:'left',marginLeft:'0%',marginTop:'20px',overflow:'hidden'}}>
                                    <image alt="logo" src={`${API_URL}/${institution.pro_logo}`} style={{width:'100px',height:'100px'}} />
                              </div>
                              <div style={{width:'5%'}}></div>
                              <div className="invoice-desc" style={{width:'60%',float:'right',marginLeft:'5px',textAlign:'center'}}>
                                    <h2 style={{textAlign:'center'}}>{institution.pro_name}</h2>
                                    <Font family='Roboto'  sans-serif>
                                    <p style={{textAlign:'center',color:'#222',fontSize:'12px',margin:'0px',padding:'0px'}}>{institution.pro_desc}</p>
  
                                    </Font>
                              </div>
                          </div>
                             </td>
                             </tr>
                           <tr>
                             <td>
                             <PrintAbleSection   authInfo={this.state.authInfo} rcv_id={this.props.rcv_id} institution={institution} />
  
                             </td>
                             
                           </tr>
  
  
                           <tr>
                             <td>
                             <div className="invoice-footer-bottom pos"  >
                        <div className="invoice-footer" style={{marginTop:'60px'}}>
                             
                                   <p style={{float:'right',marginRight:'25px',borderTop:'1px solid #222',width:'150px',textAlign:'right'}}>Authorized By</p>
                        </div>
  
  
                        <div className="invoice-footer " >
                         
                              <p style={{float:'left',fontSize:'12px',marginLeft:'20px',color:'#222',fontWeight:'bold',margin:'0px'}}>
                                  Print Date  :  {moment().format(dateFormat)} 
                             </p>
                             <p style={{float:'left',marginRight:'12px',marginRight:'25px',fontSize:'10px',color:'#222',fontWeight:'bold',padding:'0px',margin:'0px'}}>
                                  {/* Developed By : Soft Task - Contact No = 01749-508007 */}
                             </p>
                        </div>
                        
                        </div>
                             </td>
                           </tr>
                         </tbody>
  
                         <tfoot >
                           <tr>
                           <td>
                             <div style={{height:'100px'}}></div>
                             </td>
                           </tr>
                         </tfoot>
                       </table>
  
                        
                      </tbody>
                      </html>
                     ):''
                 }
                    
           
      </div>
    );
  }
}

let PurchaseVoucher = ({authInfo,rcv_id,institution})=>{
  let componentRef = useRef()
  return (
    <>
            <Grid container>
                  <Grid item xs={12} sm={11} className={"invoice-section"}>
                    {/* Main Grid Start */}
                    <div>
                    <ReactToPrint
                      trigger={() => <PrintIcon  style={{cursor:'pointer',marginLeft: '18px'}} />}
                      content={() => componentRef}
                      onBeforePrint = {() => componentRef}
                    />

    <ComponentToPrint ref={el => (componentRef = el)} authInfo={authInfo} rcv_id={rcv_id} institution={institution} />


                    <PrintAbleSection  authInfo={authInfo} rcv_id={rcv_id} institution={institution} />

                  </div>
                  </Grid>
            </Grid>
    </>
  )
}



  const mapStateToPops = (state)=>{
    return {
      authInfo:state.authInfoReducer
    }
}
export default connect(mapStateToPops,{})(PurchaseVoucher);
