import React, { useState,useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import '../../commons/voucher.css'
import commaNumber from 'comma-number';
let format = commaNumber.bindWith(',', '.')
const VoucherBody = ({salesOrderData,authInfo})=>{
    const classes = useStyles();
  

         let [salesOrder,salesOrderSet] = useState([])
          let [salesOrderDetails,salesOrderDetailsSet] = useState([])

          let [is_serial,is_serial_set] = useState('')
          let [is_cal_type,is_cal_type_set] = useState('')

        useEffect(()=>{
            salesOrderSet(salesOrderData)
            
            is_serial_set(salesOrderData.is_serial)
            is_cal_type_set(salesOrderData.is_cal_type)

            if(salesOrderData.details!=undefined){
                salesOrderDetailsSet(salesOrderData.details)
            }
            },[salesOrderData])

         


      return(
          <>
        <table className={'invoice-table'}> 
          <thead>
              <tr>
                <td  align="center"  width="4%" style={{verticalAlign: 'baseline'}}>SL.</td>
                <td align="center" width="40%"> Item Name & Description</td>
                {/* {
                is_serial=='yes'?(<>
              <td align="left"   width="15%">Serials</td>

                </>):''
              } */}
              
              {
                authInfo.is_warehouse == 'yes'?(<>
                  <td align="left"   width="15%">Warehouse</td>
                </>):''
              }

<td align="center"  width="8%" style={{verticalAlign: 'baseline'}}> QTY</td>

              <td align="center"  width="7%" style={{verticalAlign: 'baseline'}}>Rate</td>
              {/* <td align="right"  width="15%">Per</td> */}
              {
                salesOrder.status != 'a'?(<><td align="right"  width="15%">Sold QTY</td></>):''
              }
              

              {
                is_cal_type == 'individual'?(<>
                     <td align="right"  width="5%">Discount%</td>
                     <td align="right"  width="5%">Vat%</td>
                </>):''
              }

              <td align="center" width="10%" style={{verticalAlign: 'baseline'}}>Amount</td>
              </tr>
                
             
          </thead>
          <tbody>
        
                    {
                        salesOrderDetails.map((item,ind)=>(<>
                               <tr>
                                 <td  style={{textAlign:'center'}}>{parseFloat(1)+ind}</td>
                                 <td  style={{textAlign:'left'}}>{item.item_name} 
                                 
                               

                                
                                 
                                 {
                                        item.is_serial == 'yes' && item.serials.length != 0?(<>
                                            {
                                            <> <br/> SL NO : 
                                            {item.serials.map((serial)=>(
                                        <> {serial.serial_number}, </> 
                                        ))}
                                        </>
                                         }
                                        </>):''
                                    }


<br/>
{item.prod_desc != null && item.prod_desc != '' ? 'Description : ' + item.prod_desc  : ''} <br style={{ display: item.prod_desc == ''  ? 'none' :''  }} />


                                {item.model_name != null ? 'Model : ' + item.model_name +' , ' : ''} <br style={{ display: item.model_name == null  ? 'none' :''  }} />
                                 {item.group_name != null ? 'Brand : ' + item.group_name +' , ' : ''}<br style={{ display: item.group_name == null  ? 'none' :'' }} />
                                 {item.origin_name != null ? 'Origin : ' + item.origin_name  : ''}<br style={{ display: item.origin_name == null  ? 'none' :'' }} />
                                 {/* { item.category_name != null ? item.category_name : ''} */}


                                 </td>
                                 {/* {
                                    is_serial == 'yes'?(<>
                                    <td align="left"  width="15%">
                                    {
                                        item.is_serial == 'yes'?(<>
                                            {
                                            <>
                                            ({item.serials.map((serial)=>(
                                        <> <span>{serial.serial_number}</span>, </> 
                                        ))})
                                        </>
                                         }
                                        </>):''
                                    }
                                  </td>
                    
                                   </>):''
                                  } */}

                  {
                   authInfo.is_warehouse =='yes'?(<>
                          <td align="left">{item.warehouse_name}</td>
                   </>):''
                 }
                  {/* <td align="right">{item.per_unit_symbol}</td> */}
                  <td align="center">{item.qty_display}</td>
                  <td align="center">{format(parseFloat(item.item_rate).toFixed(2))}</td>

                  {
                   salesOrder.status != 'a'?(<><td align="right"  >{item.done_qty_display}</td></>):''
                 }
                  {
                    is_cal_type == 'individual' ? (<>
                         <td align="right">{item.item_discount_per}%</td>
                          <td align="right">{item.item_tax_per}%</td>
                    </>):''
                  }
                
                  <td align="center">{format(parseFloat(item.item_total).toFixed(2))}</td>

                                </tr>
                        </>))
                    }
          
                
           

               
              

              
          </tbody>
        </table>
          </>
      )
}


const useStyles = makeStyles((theme) => ({
    
    
  }));

export default VoucherBody;
